import { useTranslation } from 'react-i18next'

const LanguageSwitcher = () => {
  const { i18n } = useTranslation()

  return (
    <div className='lang-switcher'>
      {}
      <button
        className={`label ${i18n.language === 'de' ? '--active' : null}`}
        onClick={() => i18n.changeLanguage('de')}
      >
        DE
      </button>{' '}
      <button
        className={`label ${i18n.language === 'en' ? '--active' : null}`}
        onClick={() => i18n.changeLanguage('en')}
      >
        EN
      </button>
    </div>
  )
}

export default LanguageSwitcher
