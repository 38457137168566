const FormMessage = ({ type, headline, msg }) => {
  if (type === 'success') {
    return (
      <div className='message text-green-700 rounded bg-green-200 p-4 lg:p-6 my-4 lg:my-8'>
        <h3 className='headline h3 text-green-700 mb-2'>{headline}</h3>
        <p className='text-sm mb-0'>{msg}</p>
      </div>
    )
  }
  if (type === 'error') {
    return (
      <div className='message text-red-700 rounded bg-red-200 p-4 lg:p-6 my-4 lg:my-8'>
        <h3 className='headline h3 text-red-700 mb-2'>{headline}</h3>
        <p className='text-sm mb-0'>{msg}</p>
      </div>
    )
  }
}

export { FormMessage }
