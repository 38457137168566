import { useTranslation } from 'react-i18next'
import IconConnectorTruck from '../Icons/ConnectorTruck'

const ResultHead = ({ result }) => {
  const { t } = useTranslation()

  return (
    <div className='result__head'>
      <h4 className='headline h4'>{t('result.headline')}:</h4>
      <div className='result__roofline'>
        <span className='roofline__text'>
          {t('result.roofline.department', {
            depZip: result.departure.zip,
            depCountry: result.departure.country
          })}
        </span>
        <span className='connector'>
          <IconConnectorTruck />
        </span>
        <span className='roofline__text'>
          {t('result.roofline.destination', {
            desZip: result.destination.zip.toUpperCase(),
            desCountry: result.destination.country
          })}
        </span>
      </div>
      <div className='result__headline'>
        <h1 className='h1 headline print:text-white'>
          {result.daysFrom} – {result.daysTo} {t('result.days')}
        </h1>
      </div>
    </div>
  )
}

export default ResultHead
