import { getTransitTime } from './getTransitTime'
import { getDestinationCountries } from './getDestinationCountries'
import { getDepatureCountries } from './getDepartureCountries'

const API = {
  getTransitTime,
  getDepatureCountries,
  getDestinationCountries
}

export { API }
