import SVGMap2 from './Map2'
import { useStore } from '../../store/index'

const Map = () => {
  const currentDepartureCountry = useStore((state) => state.currentDepartureCountry)
  const currentDestinationCountry = useStore((state) => state.currentDestinationCountry)

  return (
    <div className='map'>
      <SVGMap2 departureCountry={currentDepartureCountry} destinationCountry={currentDestinationCountry} />
    </div>
  )
}

export default Map
