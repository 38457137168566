const IconCheckCircleBorder = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'>
    <path
      stroke='#3C5B7B'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.25'
      d='M18.333 9.233V10a8.333 8.333 0 11-4.941-7.617'
    ></path>
    <path
      stroke='#3C5B7B'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.25'
      d='M18.333 3.333L10 11.675l-2.5-2.5'
    ></path>
  </svg>
)

export default IconCheckCircleBorder
