const IconPalette = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' fill='none' viewBox='0 0 64 64'>
    <rect width='17.948' height='14' x='22.61' y='49' stroke='currentColor' strokeWidth='2' rx='1'></rect>
    <rect width='17.948' height='14' x='40.896' y='49' stroke='currentColor' strokeWidth='2' rx='1'></rect>
    <rect width='17.948' height='14' x='40.896' y='34.6' stroke='currentColor' strokeWidth='2' rx='1'></rect>
    <rect width='62' height='62' x='1' y='1' stroke='currentColor' strokeWidth='2' rx='1'></rect>
    <rect width='53.688' height='58' x='5.156' y='5' stroke='currentColor' strokeWidth='2' rx='1'></rect>
    <path
      stroke='currentColor'
      strokeLinecap='square'
      strokeWidth='2'
      d='M4.987 11.2h54.026M4.987 17.6h54.026M4.987 24h54.026'
    ></path>
    <rect width='6.649' height='4' x='46.545' y='37.6' fill='#fff' rx='1'></rect>
    <rect width='6.649' height='4' x='46.545' y='37.6' fill='currentColor' rx='1'></rect>
    <rect width='6.649' height='4' x='46.545' y='52' fill='#fff' rx='1'></rect>
    <rect width='6.649' height='4' x='46.545' y='52' fill='currentColor' rx='1'></rect>
    <rect width='6.649' height='4' x='28.26' y='52' fill='#fff' rx='1'></rect>
    <rect width='6.649' height='4' x='28.26' y='52' fill='currentColor' rx='1'></rect>
  </svg>
)

export default IconPalette
