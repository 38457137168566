const variants = {
  initial: {
    opacity: 0,
    y: 200,
    transition: { duration: 0.5 }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 }
  },
  remove: {
    opacity: 0,
    y: 200,
    transition: { duration: 0.5 }
  }
}

export { variants }
