import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { useStore } from '../../../store'
import { InputErrorMessage } from '../Messages/InputErrorMessage'
import { handleLeadFormSubmission } from '../../Helpers/handleLeadFormSubmission'
import { useState } from 'react'
import { FormMessage } from '../Messages/FormMessage'
import { FormHead } from './FormHead'
import { variants } from './AnimationVariants'

const LeadForm = () => {
  const { t } = useTranslation()
  const result = useStore((state) => state.result)
  const [formSuccess, setFormSuccess] = useState(false)
  const [formError, setFormError] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: 'all'
  })

  const onSubmit = async (data) => {
    const res = await handleLeadFormSubmission(result, data)
    res.ok ? setFormSuccess(true) : setFormError(true)
  }

  return (
    <div className='form'>
      <FormHead
        headline={t('leadForm.headline')}
        hint={t('leadForm.portalHint')}
        resultDeparture={t('leadForm.route.departure', {
          depCountry: result.departure.country,
          depZip: result.departure.zip
        })}
        resultDestination={t('leadForm.route.destination', {
          desCountry: result.destination.country,
          desZip: result.destination.zip
        })}
      />

      <AnimatePresence initial={false} mode={'popLayout'}>
        {formSuccess && (
          <motion.div key={1} variants={variants} initial='initial' animate='visible' exit='remove'>
            <FormMessage type='success' headline={t('leadForm.messages.formSuccessHeadline')} msg={t('leadForm.messages.formSuccess')} />
          </motion.div>
        )}
        {formError && (
          <motion.div key={2} variants={variants} initial='initial' animate='visible' exit='remove'>
            <FormMessage type='error' headline={t('leadForm.messages.formErrorHeadline')} msg={t('leadForm.messages.formError')} />
          </motion.div>
        )}
        {!formSuccess && (
          <motion.div key={3} variants={variants} initial='visible' animate='visible' exit='remove'>
            <form className={`mt-4 overflow-hidden`} onSubmit={handleSubmit(onSubmit)}>
              <div className='form__item'>
                <label htmlFor='company' className='form__label'>
                  {t('leadForm.items.company.label')}
                </label>
                <input
                  className='form__input --text'
                  type='text'
                  name='company'
                  id='company'
                  {...register('company', { required: true, minLength: 3 })}
                />
                {errors.company && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
              </div>
              <div className='grid grid-cols-4 gap-4'>
                <div className='form__item col-span-1'>
                  <label htmlFor='zip' className='form__label'>
                    {t('leadForm.items.zip.label')}
                  </label>
                  <input
                    className='form__input --text'
                    type='text'
                    name='zip'
                    id='zip'
                    {...register('zip', { required: true, maxLength: 10 })}
                  />
                  {errors.zip?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
                  {errors.zip?.type === 'maxLength' && <InputErrorMessage msg={t('leadForm.errors.field_zip_length')} />}
                </div>
                <div className='form__item col-span-3'>
                  <label htmlFor='city' className='form__label'>
                    {t('leadForm.items.city.label')}
                  </label>
                  <input className='form__input --text' type='text' name='city' id='city' {...register('city', { required: true })} />
                  {errors.city?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
                </div>
              </div>
              <div className='grid grid-cols-4 gap-4'>
                <div className='form__item col-span-2'>
                  <label htmlFor='lastname' className='form__label'>
                    {t('leadForm.items.lastname.label')}
                  </label>
                  <input
                    className='form__input --text'
                    type='text'
                    name='lastname'
                    id='lastname'
                    {...register('lastname', { required: true })}
                  />
                  {errors.lastname?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
                </div>
                <div className='form__item col-span-2'>
                  <label htmlFor='firstname' className='form__label'>
                    {t('leadForm.items.firstname.label')}
                  </label>
                  <input
                    className='form__input --text'
                    type='text'
                    name='firstname'
                    id='firstname'
                    {...register('firstname', { required: true })}
                  />
                  {errors.firstname?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
                </div>
              </div>
              <div className='grid grid-cols-4 gap-4'>
                <div className='form__item col-span-2'>
                  <label htmlFor='phone' className='form__label'>
                    {t('leadForm.items.phone.label')}
                  </label>
                  <input className='form__input --text' type='text' name='phone' id='phone' {...register('phone')} />
                  {/* {errors.phone?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />} */}
                </div>
                <div className='form__item col-span-2'>
                  <label htmlFor='mail' className='form__label'>
                    {t('leadForm.items.mail.label')}
                  </label>
                  <input className='form__input --text' type='text' name='mail' id='mail' {...register('mail', { required: true })} />
                  {errors.mail?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
                </div>
              </div>
              <div className='form__item'>
                <label htmlFor='inquiry' className='form__label'>
                  {t('leadForm.items.inquiry.label')}
                </label>
                <textarea
                  className='form__textarea h-18 leading-relaxed placeholder:leading-relaxed placeholder:normal-case placeholder:tracking-normal'
                  name='inquiry'
                  id='inquiry'
                  placeholder={t('leadForm.items.inquiry.placeholder')}
                  {...register('inquiry', { require: true })}
                />
                {errors.inquiry && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
              </div>
              <div className='form__item --checkbox'>
                <input
                  className='form__input --checkbox'
                  type='checkbox'
                  name='privacy'
                  id='privacy'
                  {...register('privacy', { required: true })}
                />
                <label htmlFor='privacy' className='form__label items-start justify-start'>
                  <span
                    className='text-small text-[var(--color-gray-medium)]'
                    dangerouslySetInnerHTML={{ __html: t('leadForm.items.privacy.label') }}
                  />
                </label>
                {errors.privacy?.type === 'required' && <InputErrorMessage msg={t('leadForm.errors.field_required')} />}
              </div>
              <div className='form__actions my-4 lg:my-8 grid grid-cols-4 gap-4'>
                <div className='form__item col-span-2'></div>
                <div className='form__action form__item --submit col-span-2'>
                  <button className={`button --primary`} type='submit' disabled={!isDirty || !isValid}>
                    {t('leadForm.buttons.submit.default')}
                  </button>
                </div>
              </div>
            </form>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export { LeadForm }
