const IconRequestPencil = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none' viewBox='0 0 26 26'>
    <circle cx='13' cy='13' r='12.25' stroke='#fff' strokeWidth='1.5'></circle>
    <path
      stroke='#fff'
      d='M9.3 13.744l-1.592 4.553 4.78-1.821 5.917-5.918m-9.104 3.186l2.731 2.732m-2.731-2.732L15.446 7.6m0 0l1.821-1.821 2.959 2.959-1.82 1.82M15.445 7.6l2.96 2.959'
    ></path>
  </svg>
)

export { IconRequestPencil }
