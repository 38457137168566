const getDepatureCountries = async () => {
  const SERVER = 'https://transittimes-api.hellmann.com'
  const ENDPOINT = 'getDepartureCountries'

  const response = await fetch(`${SERVER}/${ENDPOINT}`)

  if (!response.ok) {
    return { ready: false, error: true, errorMessage: 'Error fetching getDepartureCountries' }
  }

  const data = await response.json()

  return data
}

export { getDepatureCountries }
