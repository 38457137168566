const Destination = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none' viewBox='0 0 29 29'>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#a)'>
      <path d='M12.76 13.884l6.307 13.775a.821.821 0 001.474-.157l1.85-5.087 5.123-1.873a.834.834 0 00.145-1.523l-13.775-6.295a.857.857 0 00-1.124 1.16v0zM26.245 14.5A12.687 12.687 0 1014.5 26.245M20.614 11.781a7.249 7.249 0 10-8.833 8.833'></path>
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h29v29H0z'></path>
      </clipPath>
    </defs>
  </svg>
)

export default Destination
