const IconDownload = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='27' fill='none' viewBox='0 0 26 27'>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#aaaa)'>
      <path d='M13 25.727c6.581 0 11.917-5.336 11.917-11.917C24.917 7.229 19.58 1.893 13 1.893 6.419 1.893 1.083 7.23 1.083 13.81c0 6.581 5.336 11.917 11.917 11.917zm0-4.875V6.768'></path>
      <path d='M18.958 14.893L13 20.852l-5.958-5.959'></path>
    </g>
    <defs>
      <clipPath id='aaaa'>
        <path fill='currentColor' d='M0 0h26v26H0z' transform='translate(0 .81)'></path>
      </clipPath>
    </defs>
  </svg>
)

export default IconDownload
