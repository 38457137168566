const IconDimensions = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='67' height='66' fill='none' viewBox='0 0 67 66'>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M60.132 13.616c0-.441-.134-.873-.386-1.242a2.344 2.344 0 00-1.034-.836L33.858 1.188a2.472 2.472 0 00-1.894 0L7.11 11.538a2.344 2.344 0 00-1.034.836c-.252.37-.386.8-.386 1.242v26.468c0 .447.138.885.397 1.257.26.372.628.663 1.059.835l26.002 10.375a2.46 2.46 0 001.893-.03L58.745 42.2a2.34 2.34 0 001.01-.836c.246-.366.377-.792.377-1.227v-26.52zm-26.038 9.846L6.128 12.304m27.967 11.159l25.609-11.148M21.519 5.544l26.276 11.964'
    ></path>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M26.993 29.02a1.1 1.1 0 00-.193-.62 1.172 1.172 0 00-.517-.418l-11.835-4.96a1.23 1.23 0 00-1.122.092c-.165.103-.3.244-.393.41a1.095 1.095 0 00-.142.54v8.857c0 .22.067.436.194.62.126.185.305.33.516.419l11.835 4.959a1.231 1.231 0 001.122-.09c.164-.103.3-.244.393-.41.093-.165.142-.35.142-.538v-8.86zm7.102 23.657V23.462'
    ></path>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth='2'
      d='M5.901 41l-3.9 1.6M2 42.6l31.746 12.8M60.48 40.2l5.43 1.6m-.417 0L34.582 55.303M2 52.118V42.6M34.164 65L2.14 52.2m32.024 3.298v9.28M40.43 53v9.2M34.164 65l6.265-2.8m19.216-17.78v9.38m6.265-11.797V51m-6.265 2.8L65.91 51m-18.797-.8V59m6.266-11.6v9.2m-6.266 2.8l6.266-2.8m6.266-2.8l-6.17-2.37m-6.362 7.97L40.6 57'
    ></path>
  </svg>
)

export default IconDimensions
