import IconCheckCircleBorder from '../Icons/CheckCircleBorder'
import IconInfo from '../Icons/Info'
import IconPlus from '../Icons/Plus'

const ServiceListItem = ({ children, icon = false }) => (
  <li className='list__item2 items-start print:flex print:flex-row print:mb-2 print:items-center'>
    {icon && (
      <div className='icon list__icon mt-[3px] w-4 h-4 print:w-5 print:text-[#3c5c7c] print:h-5'>
        {icon === 'check' && <IconCheckCircleBorder />}
        {icon === 'info' && <IconInfo />}
        {icon === 'plus' && <IconPlus />}
      </div>
    )}
    <span className={`list__text ${icon ? '--with-icon ml-2 print:ml-1' : '--wo-icon'}`}>{children}</span>
  </li>
)

export default ServiceListItem
