const IconLamp = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='none' viewBox='0 0 26 26'>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' clipPath='url(#clip0_987_25570)'>
      <path
        strokeWidth='1.5'
        d='M19.446 15.056a6.566 6.566 0 10-9.492 5.853v3.227a.732.732 0 00.731.713h4.39a.732.732 0 00.732-.713v-3.283a6.564 6.564 0 003.64-5.797v0z'
      ></path>
      <path
        strokeWidth='1.714'
        d='M12.88 1.568v2.814m7.505-.132l-1.989 2.008m6.266 3.808h-2.814M5.377 4.25l1.989 2.008M1.1 10.066h2.814'
      ></path>
    </g>
    <defs>
      <clipPath id='clip0_987_25570'>
        <path fill='#fff' d='M0 .813h26v24.375H0z'></path>
      </clipPath>
    </defs>
  </svg>
)

export { IconLamp }
