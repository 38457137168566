import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import useFormPersist from 'react-hook-form-persist'
import { useStore } from '../../../store/index'
import { InputErrorMessage } from '../Messages/InputErrorMessage'
import IconDeparture from '../../Icons/Departure'
import IconDestination from '../../Icons/Destination'
import { useEffect } from 'react'

const TransitTime = () => {
  const departureCountries = useStore((state) => state.departureCountries)
  const destinationCountries = useStore((state) => state.destinationCountries)
  const setCurrentDepartureCountry = useStore((state) => state.setCurrentDepartureCountry)
  const setCurrentDestinationCountry = useStore((state) => state.setCurrentDestinationCountry)
  const setTransitFormData = useStore((state) => state.setTransitFormData)

  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid, isDirty }
  } = useForm({
    mode: 'all'
  })

  useFormPersist('transitTimesForm', {
    watch,
    setValue,
    storage: window.localStorage
  })

  const onSubmit = (data) => {
    if (data) {
      setTransitFormData(data)
    }
  }

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.departure_country) {
        setCurrentDepartureCountry(data.departure_country)
      }
      if (data.destination_country) {
        setCurrentDestinationCountry(data.destination_country)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch, setCurrentDepartureCountry, setCurrentDestinationCountry])

  return (
    <div className='form'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className='form__headline headline h3'>
          <span className='icon mr-3'>
            <IconDeparture />
          </span>
          <span className='text'>{t('form.departure.headline')}</span>
        </h3>
        <div className='form__group --departure'>
          <div className='form__item'>
            <label htmlFor='departure_country' className='form__label'>
              {t('form.departure.items.country')}
            </label>
            <select
              className='form__select'
              name='departure_country'
              id='departure_country'
              defaultValue={''}
              {...register('departure_country', { required: true })}
            >
              <option value=''>{t('form.general.choose')}</option>
              {departureCountries &&
                departureCountries.map((country) => (
                  <option key={country.iso} value={country.iso}>
                    {country.name}
                  </option>
                ))}
            </select>
            {errors.departure_country && <InputErrorMessage msg={t('form.errors.field_required')} />}
          </div>
          <div className='form__item'>
            <label htmlFor='departure_zip' className='form__label'>
              {t('form.departure.items.zip')}&nbsp;
              <span className='form__hint'>({t('form.departure.items.zip_hint')})</span>
            </label>
            <input
              className='form__input --text'
              type='text'
              name='depature_zip'
              id='departure_zip'
              {...register('departure_zip', { required: true, pattern: /([a-zA-Z]|[0-9]){2}$/, maxLength: 2 })}
            />
            {errors.departure_zip?.type === 'required' && <InputErrorMessage msg={t('form.errors.field_required')} />}
            {errors.departure_zip?.type === 'pattern' && <InputErrorMessage msg={t('form.errors.field_zip_pattern')} />}
            {errors.departure_zip?.type === 'maxLength' && <InputErrorMessage msg={t('form.errors.field_maxLength')} />}
          </div>
        </div>

        <h3 className='form__headline headline h3'>
          <span className='icon'>
            <IconDestination />
          </span>
          <span className='text'>{t('form.destination.headline')}</span>
        </h3>
        <div className='form__group --destination'>
          <div className='form__item'>
            <label htmlFor='destination_country' className='form__label'>
              {t('form.destination.items.country')}
            </label>
            <select
              className='form__select'
              name='destination_country'
              id='destination_country'
              defaultValue={''}
              {...register('destination_country', { required: true })}
            >
              <option value=''>{t('form.general.choose')}</option>
              {destinationCountries &&
                destinationCountries.map((country) => (
                  <option key={country.iso} value={country.iso}>
                    {country.name}
                  </option>
                ))}
            </select>
            {errors.destination_country && <InputErrorMessage msg={t('form.errors.field_required')} />}
          </div>
          <div className='form__item'>
            <label htmlFor='destination_zip' className='form__label'>
              {t('form.destination.items.zip')}&nbsp;
              <span className='form__hint'>({t('form.destination.items.zip_hint')})</span>
            </label>
            <input
              className='form__input --text'
              type='text'
              name='destination_zip'
              id='destination_zip'
              {...register('destination_zip', { required: true, pattern: /([a-zA-Z]|[0-9]){2}$/, maxLength: 2 })}
            />
            {errors.destination_zip?.type === 'required' && <InputErrorMessage msg={t('form.errors.field_required')} />}
            {errors.destination_zip?.type === 'pattern' && <InputErrorMessage msg={t('form.errors.field_zip_pattern')} />}
            {errors.destination_zip?.type === 'maxLength' && <InputErrorMessage msg={t('form.errors.field_maxLength')} />}
          </div>
        </div>
        <div className='form__actions form__group'>
          <div className='form__action form__item --submit'>
            <button className={`button --primary`} type='submit' disabled={!isDirty || !isValid}>
              {t('form.buttons.submit.default')}
            </button>
          </div>
          <div className='form__item'></div>
        </div>
      </form>
    </div>
  )
}

export default TransitTime
