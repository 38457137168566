import { useTranslation } from 'react-i18next'
import { useStore } from '../../store'
import IconDownload from '../Icons/Download'
import { IconRequestPencil } from '../Icons/RequestPencil'

const ResultActions = ({ handlePrint }) => {
  const { t } = useTranslation()
  const setActiveStep = useStore((state) => state.setActiveStep)

  return (
    <div className='result__actions flex flex-col justify-center pb-4 lg:pb-8'>
      <button
        className='button --primary w-full print:hidden flex flex-row justify-center items-center p-2'
        onClick={() => setActiveStep(3)}
      >
        <span className='button__icon mr-2'>
          <IconRequestPencil />
        </span>
        <span>{t('result.actions.requestTransit')}</span>
      </button>
      <button onClick={handlePrint} className='button --secondary mt-3 w-full print:hidden flex flex-row justify-center items-center p-2'>
        <span className='button__icon mr-2'>
          <IconDownload />
        </span>
        <span>{t('result.actions.printPdf')}</span>
      </button>
    </div>
  )
}

export { ResultActions }
