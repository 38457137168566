import { useTranslation } from 'react-i18next'
import LogoGeese from '../Icons/hellmann-worldwide-geese-icon'

const ResultPrintHeadline = () => {
  const { t } = useTranslation()
  return (
    <div className='result__printheadline'>
      <span className='logo'>
        <LogoGeese />
      </span>
      <h1 className='headline h1'>{t('title')}</h1>
    </div>
  )
}

export default ResultPrintHeadline
