import { convertUnixToDate } from '../Helpers/convertUnixToDate'

const getTransitTime = async (formData) => {
  const SERVER = 'https://transittimes-api.hellmann.com'
  const ENDPOINT = 'getTransitTimeByCountryAndDepartPostcode'
  const { departure_country, destination_country, destination_zip, departure_zip } = formData

  const response = await fetch(
    `${SERVER}/${ENDPOINT}?countryDepart=${departure_country}&countryReceive=${destination_country}&postcode=${destination_zip}&departPostcode=${departure_zip}`
  )

  if (!response.ok) {
    return {
      ready: false,
      error: true,
      errorMessage: 'Error fetching getTransitTime'
    }
  }

  const data = await response.json()

  if (data.transitFrom && data.transitTo) {
    return {
      ready: true,
      error: false,
      errorMessage: null,
      departure: {
        zip: formData?.departure_zip || null,
        country: formData?.departure_country || null
      },
      destination: {
        zip: formData?.destination_zip || null,
        country: formData?.destination_country || null
      },
      daysFrom: data.transitFrom ? data.transitFrom : false,
      daysTo: data.transitTo === 'A' || data.transitTo === 'B' || data.transitTo === 'C' ? '*' : data.transitTo,
      services: data.services ? data.services : 'HWL',
      contactMail: data.contact_mail ? data.contact_mail : 'tagespreis@hellmann.com',
      specialHandling: data.transitTo === 'A' || data.transitTo === 'B' || data.transitTo === 'C' ? true : false,
      specialText: data.transitTo,
      summer: {
        from: data.summerFrom !== null ? convertUnixToDate(data.summerFrom, 'de-DE') : null,
        to: data.summerTo !== null ? convertUnixToDate(data.summerTo, 'de-DE') : null
      },
      winter: {
        from: data.winterFrom !== null ? convertUnixToDate(data.winterFrom, 'de-DE') : null,
        to: data.winterTo !== null ? convertUnixToDate(data.winterTo, 'de-DE') : null
      },
      metropoleHeight: data.metropoleHeight
    }
  } else {
    return {
      departure: {
        zip: formData?.departure_zip || null,
        country: formData?.departure_country || null
      },
      destination: {
        zip: formData?.destination_zip || null,
        country: formData?.destination_country || null
      },
      ready: false,
      error: true,
      errorMessage: 'default'
    }
  }
}

export { getTransitTime }
