import { getName, overwrite } from 'country-list'

overwrite([
  {
    code: 'XK',
    name: 'Kosovo'
  },
  {
    code: 'GB',
    name: 'United Kingdom'
  }
])

const setCountryNamensAndSort = (countries) => {
  const countriesArrayWithNames = countries.map((country) => {
    return { iso: country, name: getName(country) }
  })
  const sortedCountries = countriesArrayWithNames.sort((a, b) => a.name.localeCompare(b.name))
  return sortedCountries
}

export { setCountryNamensAndSort }
