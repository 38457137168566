const handleLeadFormSubmission = async (result, data) => {
  // Set form data
  const url = 'https://transittimes-api.hellmann.com/leads'
  data.departureCountry = result.departure.country
  data.departureZip = result.departure.zip
  data.destinationCountry = result.destination.country
  data.destinationZip = result.destination.zip

  const fd = new FormData()
  Object.keys(data).forEach((key) => fd.append(key, data[key]))

  // Set options
  const options = {
    method: 'POST',
    body: fd
  }

  // Try to post
  try {
    const postResponse = await fetch(url, options)
    return postResponse
  } catch (e) {
    return e
  }
}

export { handleLeadFormSubmission }
