const IconDeparture = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='33' fill='none' viewBox='0 0 29 33'>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M14.5 32L28 25.774V13.32l-13.5 6.226L1 13.322v12.453L14.5 32zM7.136 10.492L1 13.322m27-.001l-6.136-2.829M14.5 19.547V32m0-31v9.962M9.591 5.981L14.501 1l4.908 4.981'
    ></path>
  </svg>
)

export default IconDeparture
