import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import IconDimensions from '../Icons/Dimensions'
import IconPalette from '../Icons/Palette'

const Infobox = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className='absolute z-10 right-2 top-2 cursor-pointer' onClick={() => setIsOpen((prev) => !prev)}>
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='none' viewBox='0 0 40 40'>
          <circle
            cx='20'
            cy='20'
            r='14'
            fill={`${isOpen ? 'var(--color-gray-rlight)' : 'var(--color-primary)'}`}
            transform='rotate(45 20 20)'
            className='transition-all'
          ></circle>
          <path
            className={`${isOpen ? 'hidden' : 'block'}`}
            d='M3.01666 0.0400391C1.51266 0.0400391 0.680664 0.840038 0.680664 2.24804C0.680664 3.65604 1.51266 4.45604 3.01666 4.45604C4.52066 4.45604 5.32066 3.65604 5.32066 2.24804C5.32066 0.840038 4.52066 0.0400391 3.01666 0.0400391ZM1.54466 7.30404C1.22466 7.30404 1.06466 7.46404 1.06466 7.78404V24.52C1.06466 24.84 1.22466 25 1.54466 25H4.45666C4.77666 25 4.93666 24.84 4.93666 24.52V7.78404C4.93666 7.46404 4.77666 7.30404 4.45666 7.30404H1.54466Z'
            fill='white'
            transform='translate(18,12), scale(0.65)'
          />
          <path
            className={`${isOpen ? 'block' : 'hidden'}`}
            stroke='#B1BDCA'
            strokeLinecap='round'
            d='M25.676 14.362L14.009 26.03m11.982 0L14.324 14.361'
          ></path>
        </svg>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className='infobox w-[400px] border-2 text-base bg-white overflow-hidden origin-top-right'
            initial={{ maxHeight: 0, translateY: 20, opacity: 0 }}
            animate={{ maxHeight: 600, translateY: 0, opacity: 1 }}
            exit={{ maxHeight: 0, translateY: -20, opacity: 0 }}
          >
            <div className='infobox__container p-4 lg:p-8'>
              <h3 className='infobox__headline headline h3'>{t('infobox.headline')}</h3>
              <p className='infobox__teaser'>{t('infobox.teaser')}</p>
              <div className='infobox__dimensions'>
                <div className='infobox__icon icon'>
                  <IconDimensions />
                </div>
                <div className='infobox__text'>
                  <p>{t('infobox.dimensions.width')}: ≤ 2.00</p>
                  <p>{t('infobox.dimensions.length')}: ≤ 2.40</p>
                  <p>{t('infobox.dimensions.height')}: ≤ 2.10</p>
                </div>
              </div>
              <div className='infobox__space'>
                <div className='infobox__icon icon'>
                  <IconPalette />
                </div>
                <div className='infobox__text'>
                  <p>{t('infobox.space.loading_area')}: ≤ 4.80</p>
                  <p>{t('infobox.space.loading_meter')}: ≤ 2.00</p>
                  <p>{t('infobox.space.weight')}: ≤ 2.50</p>
                </div>
              </div>
              <p className='mb-4'>{t('infobox.infotext')}</p>
              <a
                className='button --outlined'
                target='_blank'
                rel='noreferrer noopener'
                href='https://www.hellmann.com/products/direct-load'
              >
                {t('infobox.button_directload')}
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Infobox
