import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'
import { useStore } from '../../store/index'

const BreadcrumbItem = ({ stepNo, text }) => {
  const { activeStep, setActiveStep } = useStore((state) => ({ activeStep: state.activeStep, setActiveStep: state.setActiveStep }), shallow)

  return (
    <li className={`breadcrumb__item flex flex-1 ${activeStep === stepNo ? ' active' : ''}`}>
      <button
        className={`breadcrumb__button text-xs tracking-widest font-semibold pl-4 uppercase text-center w-full h-[26px] ${
          activeStep === stepNo ? `bg-[var(--color-primary)] text-white` : `bg-[var(--color-gray-rlight)]`
        }`}
        onClick={() => setActiveStep(stepNo)}
      >
        {text}
      </button>
    </li>
  )
}

const Breadcrumb = () => {
  const { t } = useTranslation()

  return (
    <ul className='breadcrumb flex flex-row mb-4 lg:mb-8'>
      <BreadcrumbItem stepNo={1} text={t('breadcrumb.input')} />
      <BreadcrumbItem stepNo={2} text={t('breadcrumb.result')} />
      <BreadcrumbItem stepNo={3} text={t('breadcrumb.inquiry')} />
    </ul>
  )
}

export default Breadcrumb
