import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ServiceList from '../Result/ServiceList'

const Accordion = ({ teaser, headline, listItems }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='accordion'>
      <div className={`accordion__item  tabs__item hwl__rounded${isOpen ? ' active' : ''}`}>
        <div className='accordion__item__toggler tabs__item__headline' onClick={() => setIsOpen((prev) => !prev)}>
          <h4 className='headline h4'>{headline}</h4>
          <div className='icon --circled'>
            <svg viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
              <g strokeWidth='1.5' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
                <path className='line-y' stroke='currentColor' strokeWidth='1.917' d='M24 2.917v42.166'></path>
                <path className='line-x' stroke='currentColor' strokeWidth='1.917' d='M45.083 24H2.917'></path>
              </g>
            </svg>
          </div>
        </div>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.div
              className='accordion__item__content tabs__item__content px-4'
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
            >
              <p className='mt-2 mb-3'>{teaser}</p>
              <ServiceList>{listItems.map((item) => item)}</ServiceList>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="accordion__printitem hidden print:flex">
          <motion.div
            className='accordion__item__content tabs__item__content px-4'
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            <p className='mt-2 mb-3'>{teaser}</p>
            <ServiceList>{listItems.map((item) => item)}</ServiceList>
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export { Accordion }
