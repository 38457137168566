import { useTranslation } from 'react-i18next'
import { Accordion } from '../Accordion'
import ServiceList from './ServiceList'
import ServiceListItem from './ServiceListItem'

const ResultBody = ({ result }) => {
  const { t } = useTranslation()

  const ListItems = () => {
    const items = []

    // if (result.summer.from !== null) {
    //   items.push(
    //     <ServiceListItem icon={'info'} key={1}>
    //       {t('result.content.country_infos.list.summer_schedule', {
    //         from: result.summer.from,
    //         to: result.summer.to
    //       })}
    //     </ServiceListItem>
    //   )
    // }

    // if (result.winter.from !== null) {
    //   items.push(
    //     <ServiceListItem icon={'info'} key={2}>
    //       {t('result.content.country_infos.list.winter_schedule', {
    //         from: result.winter.from,
    //         to: result.winter.to
    //       })}
    //     </ServiceListItem>
    //   )
    // }

    if (result.metropoleHeight !== null) {
      items.push(
        <ServiceListItem icon={'info'} key={3}>
          {t('result.content.country_infos.list.max_height_lifting', {
            height: result.metropoleHeight.toFixed(2)
          })}
        </ServiceListItem>
      )
    }

    return items
  }

  return (
    <div className='result__body'>
      <div className='result__list text-sm --standard-services'>
        <h4 className='headline h4'>{t('result.content.standard_services.headline')}</h4>
        <ServiceList>
          <ServiceListItem icon={'check'}>{t('result.content.standard_services.list.daily_departure')}</ServiceListItem>
          <ServiceListItem icon={'check'}>{t('result.content.standard_services.list.track_trace')}</ServiceListItem>
          <ServiceListItem icon={'check'}>{t('result.content.standard_services.list.shipping_data')}</ServiceListItem>
        </ServiceList>
      </div>
      <div className='result__list text-sm --extra-services'>
        <h4 className='headline h4'>{t('result.content.extra_services.headline')}</h4>
        <ServiceList>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.premium')}</ServiceListItem>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.insurance')}</ServiceListItem>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.payment_advice')}</ServiceListItem>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.lifting_platform')}</ServiceListItem>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.customs_clearance')}</ServiceListItem>
          <ServiceListItem icon={'plus'}>{t('result.content.extra_services.list.dangerous_goods')}</ServiceListItem>
        </ServiceList>
      </div>
      <div className='result__list text-sm --country-infos'>
        <Accordion
          headline={t('result.content.country_infos.headline')}
          teaser={t('result.content.country_infos.teaser')}
          listItems={[ListItems()]}
        />
      </div>
    </div>
  )
}

export default ResultBody
