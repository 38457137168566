const variants = {
  initial: {
    opacity: 0,
    x: 200,
    transition: { duration: 0.5 }
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.5 }
  },
  remove: {
    opacity: 0,
    x: -200,
    transition: { duration: 0.5 }
  }
}
export { variants }
