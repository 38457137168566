import { create } from 'zustand'

const useStore = create((set) => ({
  departureCountries: null,
  setDepartureCountries: (newCountries) => set((state) => ({ departureCountries: newCountries })),
  destinationCountries: null,
  setDestinationCountries: (newCountries) => set((state) => ({ destinationCountries: newCountries })),
  GLOBALS: { language: 'en', standalone: true },
  setGLOBALS: (newGlobals) => set((state) => ({ GLOBALS: newGlobals })),
  transitFormData: null,
  setTransitFormData: (formdata) => {
    set((state) => {
      return { transitFormData: formdata }
    })
  },
  currentDepartureCountry: null,
  setCurrentDepartureCountry: (newCountry) => set((state) => ({ currentDepartureCountry: newCountry })),
  currentDestinationCountry: null,
  setCurrentDestinationCountry: (newCountry) => set((state) => ({ currentDestinationCountry: newCountry })),
  activeStep: 1,
  setActiveStep: (newStep) => {
    set((currentState) => {
      if (newStep === 2 && (!currentState.result.ready || currentState.result.error)) {
        return null
      }
      if (newStep === 3 && (!currentState.result.ready || currentState.result.error)) {
        return null
      }
      return { activeStep: newStep }
    })
  },
  result: { ready: false, error: false, errorMessage: null },
  setResult: (newResult) => set((state) => ({ result: newResult }))
}))

export { useStore }
