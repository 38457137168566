const IconPlus = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
    <g clipPath='url(#a)'>
      <circle cx='9' cy='9' r='8.5' stroke='currentColor'></circle>
      <rect width='10' height='1' x='4' y='8.2' fill='currentColor' rx='0.5'></rect>
      <rect width='10' height='1' x='8.2' y='14' fill='currentColor' rx='0.5' transform='rotate(-90 8.2 14)'></rect>
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M0 0h18v18H0z'></path>
      </clipPath>
    </defs>
  </svg>
)

export default IconPlus
