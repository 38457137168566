const IconInfo = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none' viewBox='0 0 17 17'>
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
      d='M8.6 16.153c4.087 0 7.4-3.354 7.4-7.49 0-4.138-3.313-7.492-7.4-7.492-4.088 0-7.402 3.354-7.402 7.491s3.314 7.491 7.401 7.491zm0-7.491v4.034'
    ></path>
    <path
      fill='currentColor'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M8.6 6.357a.572.572 0 00.568-.576.573.573 0 00-.569-.576.573.573 0 00-.57.576c0 .318.256.576.57.576z'
    ></path>
  </svg>
)

export default IconInfo
