import { motion, AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'
import { useStore } from '../../store'
import { variants } from './variants'
import TransitTime from '../Forms/TransitTime'
import Result from '../Result'
import ErrorMessage from '../Messages/Error'
import { LeadForm } from '../Forms/Lead'

const Steps = () => {
  const result = useStore((state) => state.result)
  const { activeStep, setActiveStep } = useStore((state) => ({ activeStep: state.activeStep, setActiveStep: state.setActiveStep }), shallow)

  useEffect(() => {
    setActiveStep(2)
    // eslint-disable-next-line
  }, [result])

  return (
    <div className='steps px-4 lg:px-8'>
      <AnimatePresence initial={false} mode={'popLayout'}>
        {activeStep === 1 && (
          <motion.div key={1} variants={variants} initial='initial' animate='visible' exit='remove' className='step-1'>
            {result.error && <ErrorMessage message={result.errorMessage} result={result} />}
            <TransitTime />
          </motion.div>
        )}
        {activeStep === 2 && (
          <motion.div key={2} variants={variants} initial='initial' animate='visible' exit='remove' className='step-2'>
            {!result.error && <Result />}
          </motion.div>
        )}
        {activeStep === 3 && result.ready && (
          <motion.div key={3} variants={variants} initial='initial' animate='visible' exit='remove' className='step-3'>
            <LeadForm />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Steps
