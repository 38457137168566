const convertUnixToDate = (timestamp, locale) => {
  const options = {
    dateStyle: 'short'
  }

  const milliseconds = timestamp * 1000
  const dateObject = new Date(milliseconds)

  return dateObject.toLocaleString(locale, options)
}

export { convertUnixToDate }
