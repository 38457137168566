import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../store'
import { useReactToPrint } from 'react-to-print'
import { ResultActions } from './ResultActions'
import ResultBody from './ResultBody'
import ResultHead from './ResultHead'
import ResultPrintFooter from './ResultPrintFooter'
import ResultPrintHeadline from './ResultPrintHeadline'

const Result = ({ handleStepChange }) => {
  const { t } = useTranslation()
  const result = useStore((state) => state.result)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  return (
    <div className={`result ${result.ready && `--visible`}`}>
      {result.ready && (
        <div className='result__print' ref={componentRef}>
          <ResultPrintHeadline />
          <ResultHead result={result} />
          {result.specialHandling && <div className='result__specialHandling'>{t(`result.specialHandling.${result.specialText}`)}</div>}
          <ResultBody result={result} />
          <ResultPrintFooter />
          <ResultActions handlePrint={handlePrint} handleStepChange={handleStepChange} />
        </div>
      )}
    </div>
  )
}

export default Result
