import { useTranslation } from 'react-i18next'

const ErrorMessage = ({ message, result }) => {
  const { t } = useTranslation()
  const data = {
    depCountry: result.departure.country,
    depZip: result.departure.zip,
    desCountry: result.destination.country,
    desZip: result.destination.zip
  }

  return (
    <p className='message p-3 rounded-lg text-sm border-2 bg-[var(--color-gray-rlight)] bg-red-100 border-red-300 text-red-600'>
      {t(`error.${message}`, data)}
    </p>
  )
}

export default ErrorMessage
