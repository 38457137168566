import IconConnectorTruck from '../../Icons/ConnectorTruck'
import { IconLamp } from '../../Icons/Lamp'

const FormHead = ({ headline, hint, resultDeparture, resultDestination }) => {
  return (
    <>
      <h3 className='headline h3 mb-4'>{headline}</h3>

      <div className='hint-portal flex flex-row text-sm mb-6'>
        <span className='mr-4 text-[var(--color-gray-medium)]'>
          <IconLamp />
        </span>
        <span dangerouslySetInnerHTML={{ __html: hint }} />
      </div>

      <h5 className='headline h5 text-sm leading-4 flex flex-row items-center justify-center bg-[var(--color-gray-rlight)] p-2 rounded'>
        <span className='mr-2'>{resultDeparture}</span>
        <IconConnectorTruck />
        <span className='ml-2'>{resultDestination}</span>
      </h5>
    </>
  )
}

export { FormHead }
