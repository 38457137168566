const IconConnectorTruck = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='93' height='27' fill='none' viewBox='0 0 93 27'>
    <circle cx='1' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='6' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='11' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='16' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='77' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='82' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='87' cy='14' r='1' fill='currentColor'></circle>
    <circle cx='92' cy='14' r='1' fill='currentColor'></circle>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5'>
      <path d='M50.167 19.167V5.833h10a5 5 0 015 5v10A1.667 1.667 0 0163.5 22.5h-1.667'></path>
      <path d='M55.167 25.833a3.333 3.333 0 110-6.666 3.333 3.333 0 010 6.666zm-21.667 0a3.333 3.333 0 110-6.666 3.333 3.333 0 010 6.666zm16.667-7.5v-2.5H26.833v3.334m38.334-5h-8.334a1.667 1.667 0 01-1.666-1.667V5.833M48.5 22.5H41m24.167-4.167h-3.334'></path>
      <path d='M48.5.833h-20A1.667 1.667 0 0026.833 2.5v13.333h23.334V2.5A1.666 1.666 0 0048.5.833v0z'></path>
    </g>
  </svg>
)

export default IconConnectorTruck
