import { useEffect } from 'react'
import { shallow } from 'zustand/shallow'
import './i18n'
import { useTranslation } from 'react-i18next'

import { useStore } from './store/index'
import { API } from './components/Api/index'
import { setCountryNamensAndSort } from './components/Helpers/setCountryNamensAndSort'

import Infobox from './components/Infobox'
import LanguageSwitcher from './components/LanguageSwitcher'
import Map from './components/Map'
import Breadcrumb from './components/Breadcrumb'
import Steps from './components/Steps'

const App = () => {
  const { t, i18n } = useTranslation()
  const { GLOBALS, setGLOBALS } = useStore((state) => ({ GLOBALS: state.GLOBALS, setGLOBALS: state.setGLOBALS }), shallow)
  const { departureCountries, setDepartureCountries } = useStore(
    (state) => ({ departureCountries: state.departureCountries, setDepartureCountries: state.setDepartureCountries }),
    shallow
  )
  const { destinationCountries, setDestinationCountries } = useStore(
    (state) => ({ destinationCountries: state.destinationCountries, setDestinationCountries: state.setDestinationCountries }),
    shallow
  )
  const transitFormData = useStore((state) => state.transitFormData)
  const activeStep = useStore((state) => state.activeStep)
  const { result, setResult } = useStore((state) => ({ result: state.result, setResult: state.setResult }), shallow)

  useEffect(() => {
    if (transitFormData !== null) {
      API.getTransitTime(transitFormData).then((res) => setResult(res))
    }
    // eslint-disable-next-line
  }, [transitFormData])

  useEffect(() => {
    i18n.changeLanguage(GLOBALS.language)
    // eslint-disable-next-line
  }, [GLOBALS])

  useEffect(() => {
    if (window.ttConfig) {
      setGLOBALS({
        language: window.ttConfig.language,
        standalone: window.ttConfig.standalone
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (departureCountries === null || destinationCountries === null) {
      API.getDepatureCountries()
        .then((res) => setCountryNamensAndSort(res))
        .then((depCountries) => setDepartureCountries(depCountries))
      API.getDestinationCountries()
        .then((res) => setCountryNamensAndSort(res))
        .then((desCountries) => setDestinationCountries(desCountries))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <main className='tt-main relative'>
        {GLOBALS.standalone && <LanguageSwitcher />}
        {GLOBALS.standalone && <h1 className='tt-main__headline headline h1 max-w-6xl mx-auto mb-4'>{t('title')}</h1>}
        {GLOBALS.standalone && <p className='tt-main__teaser max-w-6xl mx-auto mb-6'>{t('teaser')}</p>}
        <div className='tt-main__body relative border-b border-t flex flex-col md:flex-row mt-4 md:mt-12'>
          <div
            className={`tt-main__content transition-all duration-500 border-r flex-none ${
              activeStep === 3 ? 'lg:w-[600px]' : 'lg:w-[400px]'
            }`}
          >
            <Breadcrumb />
            <Steps />
          </div>
          <div className='tt-main__map flex-1 max-w-[810px] overflow-hidden'>
            <Map departureCountry={result.departure?.country} destinationCountry={result.destination?.country} />
          </div>
          <div className='tt-main__infobox relative md:absolute md:z-10 md:top-6 md:right-6 md:w-[400px]'>
            <Infobox />
          </div>
        </div>
      </main>
    </div>
  )
}

export default App
